import React, { useState } from 'react';
import { navLinks } from '../data';
import styles from '../styles';
import menu from '../assets/menu.svg';
import close from '../assets/close.svg';
import { NavLink } from 'react-router-dom';

export default function NavBar() {
  const [toggle, setToggle] = useState(false);

  return (
    <div className="bg-white/40 sticky py-2 top-0 z-50 flex justify-end w-screen tablet:justify-center tablet:px-16 px-6 shadow-lg">
      <div className={`${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <nav className="w-full flex py-6 justify-between items-center navbar tablet:px-12">
            <ul className="list-none mobile:flex hidden justify-between items-center flex-1">
              {navLinks.map((nav) => (
                <NavLink
                  to={`${nav.id}`}
                  key={nav.index}
                  className={({ isActive }) =>
                    isActive
                      ? 'font-sans font-normal cursor-pointer text-[16px] text-pink px-8'
                      : 'font-sans font-normal cursor-pointer text-[16px] text-eggplant px-8 hover:text-rock'
                  }
                >
                  {nav.title}
                </NavLink>
              ))}
            </ul>

            <div className="mobile:hidden flex flex-1 justify-end items-center">
              <img
                src={toggle ? close : menu}
                alt="menu"
                className="w-[28px] h-[28px] object-contain"
                onClick={() => setToggle(!toggle)}
              />

              <div
                className={`${
                  !toggle ? 'hidden' : 'flex'
                } p-6 bg-white absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar`}
              >
                <ul className="list-none flex justify-end items-start flex-1 flex-col">
                  {navLinks.map((nav) => (
                    <NavLink
                      to={`${nav.id}`}
                      key={nav.index}
                      className={({ isActive }) =>
                        isActive
                          ? 'font-sans font-normal cursor-pointer text-[16px] text-pink px-8'
                          : 'font-sans font-normal cursor-pointer text-[16px] text-eggplant px-8 hover:text-rock'
                      }
                      onClick={() => setToggle(!toggle)}
                    >
                      {nav.title}
                    </NavLink>
                  ))}
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}
