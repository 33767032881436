import React from 'react';
import rates from '../images/rates.png';

export default function Rates() {
  return (
    <section className="flex justify-center items-center bg-white border border-solid my-8">
      <div className="w-full h-3/4 min-w-[385px] z-10 absolute tablet:relative">
        <img
          src={rates}
          alt="peaceful pebbles on beach"
          className="w-full h-full"
        />
      </div>
      <div className="w-10/12 px-4 mb-8 rounded-3xl mobile:max-w-xl tablet:max-w-full laptop:max-w-screen-xl tablet:px-24 laptop:px-8 z-40 bg-white/80 shadow-xl tablet:shadow-none">
        <div className="flex flex-col items-center justify-between w-full laptop:flex-row">
          <div className="mb-16 laptop:mb-0 laptop:max-w-lg laptop:pr-5 flex items-center">
            <div className="max-w-xl my-6 mx-8">
              <h2 className="text-center tablet:text-start font-title text-6xl tablet:mt-0 mt-6 tracking-wide text-charcoal tablet:text-8xl tablet:leading-none max-w-lg pb-4">
                Rates:
              </h2>
              <p className="font-sans text-charcoal text-2xl  tablet:text-3xl py-2 tablet:py-8">
                $90 - 1hr
              </p>
              <p className="font-sans text-charcoal text-2xl  tablet:text-3xl pt-4 tablet:py-8">
                $120 - 1hr 30min
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
